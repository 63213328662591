<template>
  <button
    type="button"
    class="link-green-dots"
    style="font-size: 16px"
    @click.stop.prevent="openAvailabilityDialog"
  >
    <slot> {{ t('Є в наявності в магазинах') }} </slot>

    <AppDialogsAvailability
      v-model:show="showAvailabilityDialog"
      :variant-code="variant.code"
    />
  </button>
</template>
<script setup>
const { t } = useI18n()
defineProps({
  variant: {
    type: Object,
    default: () => {}
  }
})
const showAvailabilityDialog = ref(false)

const openAvailabilityDialog = () => {
  showAvailabilityDialog.value = true
}
</script>

<style land="postcss" scoped>
.shipping-item--description {
  @apply text-green-ibis ml-auto;
}
button.shipping-item--description {
  @apply underline decoration-dashed underline-offset-4;
}
</style>
